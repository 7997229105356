import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { environment } from '@desquare/environments';
import {
  CloudinaryVideo,
  CloudinaryImage,
  Cloudinary,
} from '@cloudinary/url-gen';
import { CloudinaryModule } from '@cloudinary/ng';
import { limitFit } from '@cloudinary/url-gen/actions/resize';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CloudinaryModule, TranslatePipe],
  selector: 'cl-media',
  template: `
    @defer (on viewport) {
      @if (mediaType === 'image') {
        <advanced-image
          [cldImg]="clImage"
          [plugins]="imagePlugins"
          [width]="width"
          [height]="height"
        ></advanced-image>
      }
      @if (mediaType === 'video') {
        <advanced-video
          [cldVid]="clVideo"
          controls
          [plugins]="videoPlugins"
          class="cld-video-player"
        ></advanced-video>
      }
      @if (mediaType === 'videoPoster') {
        <advanced-video
          [cldVid]="clVideo"
          [cldPoster]="clVideo"
        ></advanced-video>
      }
    } @placeholder {
      <div class="d-flex justify-content-center align-items-center h-100">
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        >
          <span class="text-secondary visually-hidden">
            {{ 'LOADING' | translate }}...
          </span>
        </div>
      </div>
    }
  `,
  // styleUrls: ['./cloudinaryMedia.component.scss'],
  styles: [
    `
      ::ng-deep advanced-image img {
        object-fit: contain !important;
      }
      ::ng-deep .cld-video-player video {
        width: 100% !important;
        height: 100% !important;
        max-height: 60vh !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloudinaryMediaComponent implements OnChanges {
  @Input('public-id') publicId!: string;
  @Input() loading = 'lazy';
  @Input() mediaType!: 'image' | 'video' | 'raw' | 'videoPoster';
  @Input() width: string = '100%';
  @Input() height: string = '100%';
  @Input() resizeMethod!: string | undefined;
  @Input() quality = 'auto';
  @Input('fetch-format') fetchFormat = 'auto';
  @Input() assetItem!: string;

  clImage!: CloudinaryImage;
  clVideo!: CloudinaryVideo;

  imagePlugins = [];
  videoPlugins = [];

  ngOnChanges(): void {
    const cld = new Cloudinary({
      cloud: {
        cloudName: environment.cloudinary.cloudName,
      },
    });

    if (this.publicId) {
      if (this.mediaType === 'image') {
        this.clImage = cld.image(this.publicId);
        if (this.resizeMethod === 'limit')
          this.clImage.resize(limitFit().width(this.width).height(this.height));
        this.clImage.format(this.fetchFormat);
      }
      if (this.mediaType === 'video' || this.mediaType === 'videoPoster') {
        this.clVideo = cld.video(this.publicId).format(this.fetchFormat);
        if (this.resizeMethod === 'limit')
          this.clVideo.resize(limitFit().width(this.width).height(this.height));
      }
    }
  }
}
